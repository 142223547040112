<template>
  <main>
      <div id="slide"></div>
      <div :class="`resultats_wrapper fullpage-container ${this.profil}`">
              <PopupSocial ref="social" /> 
          <div v-if="this.bottom === true" class="next"><img src="../assets/images/next.png"></div>
          <div class="menu_resultats1">
              <img @click="openQuiz" class="reload" style="opacity: 0" src="../assets/images/reload.png">
              <img @click="openSocial"  class="facebook" src="../assets/images/facebook.png">
          </div>
          <div class="fullpage-wp">
              <div class="page-1 page">
                  <div class="part-1">
                      <div class="page1_wrapper">
                          <div class="page1_left" :class="`p1l_${profil}`">
                              <img :class="{'glass-veggie': profil === 'veggie-gourmand'}" :src="`/img/profils/${this.profil}.png`">
                          </div>
                          <div class="page1_right">
                              <h3>Vous êtes</h3>
                              <img :src="`/slides/${this.profil}.png`">
                              
                              <h2 class="ascendant">ascendant 
                                <span v-if="ascendant === 'gastronomaniac'" id="element" v-html="$CD('NOM_DU_PROFIL_GASTRONOMANIAC')" />
                                <span v-if="ascendant === 'bec-sucre'" id="element" v-html="$CD('NOM_DU_PROFIL_BEC_SUCRE')" />
                                <span v-if="ascendant === 'viandard'" id="element" v-html="$CD('NOM_DU_PROFIL_VIANDARD')" />
                                <span v-if="ascendant === 'traditionista'" id="element" v-html="$CD('NOM_DU_PROFIL_TRADITIONISTA')" />
                                <span v-if="ascendant === 'aperitivore'" id="element" v-html="$CD('NOM_DU_PROFIL_APERITIVORE')" />
                                <span v-if="ascendant === 'veggie-gourmand'" id="element" v-html="$CD('NOM_DU_PROFIL_VEGGIE_GOURMAND')" />
                                <span v-if="ascendant === 'street-lover'" id="element" v-html="$CD('NOM_DU_PROFIL_STREET_LOVER')" />  
                              </h2>

                              <img class="image-mobile" :src="`/img/profils/${this.profil}.png`">
                              <h2 class="profil-title desktop">Votre profil</h2>
                              <p class="white-bg-p-10 text-align-center" v-if="profil === 'gastronomaniac'" id="element" v-html="$CD('VOTRE_PROFIL_GASTRONOMANIAC')" />
                              <p class="white-bg-p-10 text-align-center" v-if="profil === 'bec-sucre'" id="element" v-html="$CD('VOTRE_PROFIL_BEC_SUCRE')" />
                              <p class="white-bg-p-10 text-align-center" v-if="profil === 'viandard'" id="element" v-html="$CD('VOTRE_PROFIL_VIANDARD')" />
                              <p class="white-bg-p-10 text-align-center" v-if="profil === 'traditionista'" id="element" v-html="$CD('VOTRE_PROFIL_TRADITIONISTA')" />
                              <p class="white-bg-p-10 text-align-center" v-if="profil === 'aperitivore'" id="element" v-html="$CD('VOTRE_PROFIL_APERITIVORE')" />
                              <p class="white-bg-p-10 text-align-center" v-if="profil === 'veggie-gourmand'" id="element" v-html="$CD('VOTRE_PROFIL_VEGGIE_GOURMAND')" />
                              <p class="white-bg-p-10 text-align-center" v-if="profil === 'street-lover'" id="element" v-html="$CD('VOTRE_PROFIL_STREET_LOVER')" />                                                             
                          </div>
                      </div>
                      <div  class="page2_wrapper"  style="padding-top: 20px; padding-bottom: 1px; margin-bottom: 20px;">
                          <img src="../assets/images/qjb2.png">
                           <h2 class="profil-title">Qu'est-ce que je bois ?</h2>
                          <p class="white-bg-p-10 text-align-center txt-bold" v-if="profil === 'gastronomaniac'" v-html="$CD('CE_QUE_JE_BOIS_GASTRONOMANIAC')" />
                          <p class="white-bg-p-10 text-align-center txt-bold" v-if="profil === 'bec-sucre'" v-html="$CD('CE_QUE_JE_BOIS_BEC_SUCRE')" />
                          <p class="white-bg-p-10 text-align-center txt-bold" v-if="profil === 'viandard'" v-html="$CD('CE_QUE_JE_BOIS_VIANDARD')" />
                          <p class="white-bg-p-10 text-align-center txt-bold" v-if="profil === 'traditionista'" v-html="$CD('CE_QUE_JE_BOIS_TRADITIONISTA')" />
                          <p class="white-bg-p-10 text-align-center txt-bold" v-if="profil === 'aperitivore'" v-html="$CD('CE_QUE_JE_BOIS_APERITIVORE')" />
                          <p class="white-bg-p-10 text-align-center txt-bold" v-if="profil === 'veggie-gourmand'" v-html="$CD('CE_QUE_JE_BOIS_VEGGIE_GOURMAND')" />
                          <p class="white-bg-p-10 text-align-center txt-bold" v-if="profil === 'street-lover'" v-html="$CD('CE_QUE_JE_BOIS_STREET_LOVER')" />                               
                          <img src="../assets/images/ap2.png">
                          <h2 class="profil-title">Mes accords parfaits : </h2>
                          <div class="accords">
                          </div>
                          <div class="carousel-resultats">
                          <carousel  
                          v-bind:navigation-enabled="true" 
                          v-bind:paginationEnabled="false" 
                          navigationNextLabel="<img class='next-accords' src='/slides/next.png'>"
                          navigationPrevLabel="<img class='prev-accords' src='/slides/prev.png'>" 
                          v-bind:per-page-custom=[[300,1],[480,1],[780,1],[799,2],[1000,3]]
                          >
                               <slide v-for="index in [1,2,3,4]" :key="index">
                                  <div class="accord">
                                    <div class="white-bg radius-50">
                                       <img v-if="profil === 'aperitivore'" :src="`/recettes/aperitivore_${index}.png`">
                                       <img v-if="profil === 'bec-sucre'" :src="`/recettes/bec-sucre_${index}.png`">
                                       <img v-if="profil === 'gastronomaniac'" :src="`/recettes/gastronomaniac_${index}.png`">
                                       <img v-if="profil === 'street-lover'" :src="`/recettes/street-lover_${index}.png`">
                                       <img v-if="profil === 'traditionista'" :src="`/recettes/traditionista_${index}.png`">  
                                       <img v-if="profil === 'veggie-gourmand'" :src="`/recettes/veggie-gourmand_${index}.png`">
                                       <img v-if="profil === 'viandard'" :src="`/recettes/viandard_${index}.png`"> 
                                        <div class="accord-text">

                                        <h2 v-if="profil === 'gastronomaniac'" v-html="$CD(`TITRE_ACCORD_PARFAIT_${index}_GASTRONOMANIAC`)" />
                                        <h2 v-if="profil === 'bec-sucre'" v-html="$CD(`TITRE_ACCORD_PARFAIT_${index}_BEC_SUCRE`)" />
                                        <h2 v-if="profil === 'viandard'" v-html="$CD(`TITRE_ACCORD_PARFAIT_${index}_VIANDARD`)" />
                                        <h2 v-if="profil === 'traditionista'" v-html="$CD(`TITRE_ACCORD_PARFAIT_${index}_TRADITIONISTA`)" />
                                        <h2 v-if="profil === 'aperitivore'" v-html="$CD(`TITRE_ACCORD_PARFAIT_${index}_APERITIVORE`)" />
                                        <h2 v-if="profil === 'veggie-gourmand'" v-html="$CD(`TITRE_ACCORD_PARFAIT_${index}_VEGGIE_GOURMAND`)" />
                                        <h2 v-if="profil === 'street-lover'" v-html="$CD(`TITRE_ACCORD_PARFAIT_${index}_STREET_LOVER`)" />   

                                        <p v-if="profil === 'gastronomaniac'" v-html="$CD(`TEXTE_RECETTE_${index}_GASTRONOMANIAC`)" />
                                        <p v-if="profil === 'bec-sucre'" v-html="$CD(`TEXTE_RECETTE_${index}_BEC_SUCRE`)" />
                                        <p v-if="profil === 'viandard'" v-html="$CD(`TEXTE_RECETTE_${index}_VIANDARD`)" />
                                        <p v-if="profil === 'traditionista'" v-html="$CD(`TEXTE_RECETTE_${index}_TRADITIONISTA`)" />
                                        <p v-if="profil === 'aperitivore'" v-html="$CD(`TEXTE_RECETTE_${index}_APERITIVORE`)" />
                                        <p v-if="profil === 'veggie-gourmand'" v-html="$CD(`TEXTE_RECETTE_${index}_VEGGIE_GOURMAND`)" />
                                        <p v-if="profil === 'street-lover'" v-html="$CD(`TEXTE_RECETTE_${index}_STREET_LOVER`)" /> 

                                        <p v-if="profil === 'gastronomaniac'" v-html="$CD(`LISTE_VIN_${index}_GASTRONOMANIAC`)" />
                                        <p v-if="profil === 'bec-sucre'" v-html="$CD(`LISTE_VIN_${index}_BEC_SUCRE`)" />
                                        <p v-if="profil === 'viandard'" v-html="$CD(`LISTE_VIN_${index}_VIANDARD`)" />
                                        <p v-if="profil === 'traditionista'" v-html="$CD(`LISTE_VIN_${index}_TRADITIONISTA`)" />
                                        <p v-if="profil === 'aperitivore'" v-html="$CD(`LISTE_VIN_${index}_APERITIVORE`)" />
                                        <p v-if="profil === 'veggie-gourmand'" v-html="$CD(`LISTE_VIN_${index}_VEGGIE_GOURMAND`)" />
                                        <p v-if="profil === 'street-lover'" v-html="$CD(`LISTE_VIN_${index}_STREET_LOVER`)" /> 
                                        </div>
                                    </div>
                                 </div>
                              </slide>
                          </carousel> 
                          </div>
                          <img src="../assets/images/qms2.png">
                              <h2 class="profil-title">Quel modéré je suis ?</h2>
                              <div class="moderer white-bg" style="padding-top: 20px; padding-bottom: 1px; margin-bottom: 20px;color:black !important;">
                                <h2 style="color:black" v-if="profil === 'gastronomaniac'" v-html="$CD('HAIKU_MODERATION_GASTRONOMANIAC')" />
                                <h2 style="color:black" v-if="profil === 'bec-sucre'" v-html="$CD('HAIKU_MODERATION_BEC_SUCRE')" />
                                <h2 style="color:black" v-if="profil === 'viandard'" v-html="$CD('HAIKU_MODERATION_VIANDARD')" />
                                <h2 style="color:black" v-if="profil === 'traditionista'" v-html="$CD('HAIKU_MODERATION_TRADITIONISTA')" />
                                <h2 style="color:black" v-if="profil === 'aperitivore'" v-html="$CD('HAIKU_MODERATION_APERITIVORE')" />
                                <h2 style="color:black" v-if="profil === 'veggie-gourmand'" v-html="$CD('HAIKU_MODERATION_VEGGIE_GOURMAND')" />
                                <h2 style="color:black" v-if="profil === 'street-lover'" v-html="$CD('HAIKU_MODERATION_STREET_LOVER')" />   
                                <br>
                                <p v-if="profil === 'gastronomaniac'" v-html="$CD('TEXTE_QUEL_MODERE_ES_TU_GASTRONOMANIAC')" />
                                <p v-if="profil === 'bec-sucre'" v-html="$CD('TEXTE_QUEL_MODERE_ES_TU_BEC_SUCRE')" />
                                <p v-if="profil === 'viandard'" v-html="$CD('TEXTE_QUEL_MODERE_ES_TU_VIANDARD')" />
                                <p v-if="profil === 'traditionista'" v-html="$CD('TEXTE_QUEL_MODERE_ES_TU_TRADITIONISTA')" />
                                <p v-if="profil === 'aperitivore'" v-html="$CD('TEXTE_QUEL_MODERE_ES_TU_APERITIVORE')" />
                                <p v-if="profil === 'veggie-gourmand'" v-html="$CD('TEXTE_QUEL_MODERE_ES_TU_VEGGIE_GOURMAND')" />
                                <p v-if="profil === 'street-lover'" v-html="$CD('TEXTE_QUEL_MODERE_ES_TU_STREET_LOVER')" />   
                              </div>
                              <div class="moderer">
                                  <div>
                                    <router-link :to="`/img/profils/${ascendant}`" tag="button">Découvrir mon ascendant et les autres profils</router-link>
                                    <button @click="openSocial">Partagez mon profil</button>
                                  </div>
                                  <br><br>
                                  <p style="display: block; width:350px !important; margin: auto; text-align:center;" class="white-bg-p-5"><router-link to="/mentions" style="color:black;">Mentions légales</router-link></p>
                                  <div class="mobile">
                                    <br>
                                    <br>
                                  </div>
                              </div>   
                          </div>
                      </div>
                  </div>
              </div>
          </div>
       
      </div>
  </main>
</template> 
<script>
import PopupSocial from '../components/Popup-social.vue'
export default {
  data() {
    return {
      profil : undefined,       
      ascendant : undefined, 
      description : undefined, 
      opts: {
        perpage : undefined,
        bottom: true,
      }
    }
  },
 components: {
    PopupSocial, 
    'carousel': VueCarousel.Carousel,
    'slide': VueCarousel.Slide
  },  
  created() {
     this.perpage = 1; 

     this.bottom = true; 
    if(this.$route.params._profil) {
      if( this.$route.params._profil != 'traditionista' && 
          this.$route.params._profil != 'gastronomaniac' &&  
          this.$route.params._profil != 'bbq-master' && 
          this.$route.params._profil != 'street-lover' && 
          this.$route.params._profil != 'aperitivore' && 
          this.$route.params._profil != 'bec-sucre' &&           
          this.$route.params._profil != 'veggie-gourmand') {
              this.$router.push("/404");
          }

        this.profil = this.$route.params._profil
    }  else { 
        this.profil = localStorage.principal
    }
    this.ascendant = localStorage.ascendant


  },
  mounted() {
    var element = document.querySelector('.fullpage-wp');
    var btbottom = document.querySelector('.next');
    element.onscroll = (ev) => {
      btbottom.style.display = 'none';
        //if( (element.scrollTop+3000) >= element.scrollHeight - element.offsetHeight) btbottom.style.display = 'none';
        // if( (element.scrollTop+1000) <= element.scrollHeight - element.offsetHeight) btbottom.style.display = 'block';            
    };
    gtag('event', 'resultat', {
      'event_category': 'quiz',
      'event_label': `${this.profil}_${this.ascendant}`,
    });
  },
  methods:{  
    openQuiz() {
	   this.$router.push("/quiz");
    },
    openSocial() {
      this.$refs.social.openSocialPopup()
    },
    moveNext(){
     var container = this.$el.querySelector(".fullpage-wp");
     container.scrollTop = container.scrollHeight;
    }
  }
}
</script>

<style lang="scss">

@import "../scss/_variables.scss";

.text-align-center {
  text-align: center;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.VueCarousel-navigation-button {
  top: 25% !important;
}

.next-accords {
  top: 20px !important;
}

.prev-accords {
  top: 20px !important;
}

.image-mobile {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    margin: 0 auto;
    margin-bottom: -30px !important;
  }
}

.profil-title {
  margin-top: 80px;
  margin-bottom:10px;

  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
}

.fullpage-wp {
  @media screen and (max-width: 768px) {
    margin-top: 84px;
  }
}

.moderer {
  width: 100%;
  text-align: center;
  margin-bottom: 100px;

  h2 {
    font-family: $font-extra;
    font-weight: bold;
    font-style: italic;
    width: 1100px;
    margin: 0 auto;
    font-size: 60px;
    line-height: 60px;
    color:black;
    width:100%;
  }

  p {
    font-family: "acumin-pro", sans-serif;
    font-weight: 500;
    width: 768px !important;
    margin: 0 auto;
    font-size: 19px !important;
    font-weight: 500;
    line-height: 22px !important;
  }

  div {
    width: 768px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
      width: fit-content;
      display: block;
      margin: 0 auto;
    }

    button {
      font-family: "acumin-pro", sans-serif;
      font-weight: 500;
      position: relative;
      display: block;
      width: 340px !important;
      height: 70px;
      font-size: 20px;
      line-height: 20px;
      cursor: pointer;
      border-radius: 60px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      text-align: center;
      padding: 15px 20px;
      padding-top: 14px;
      margin-bottom: 20px;
      initial-letter: 2;
      border: none;

      @media screen and (max-width: 1000px) {
        font-size: 18px;
        line-height: 20px;
      }

      @media screen and (max-width: 768px) {
          width: 290px !important;
      }     
    }

    button:hover {
      background-color: white;
      color: black;
    }
  }

  @media screen and (max-width: 1200px) {
    h2 {
      width: 92%;
    }

    p {
      width: 92% !important;
    }
  }

  @media screen and (max-width: 1000px) {
    width: 92%;
    margin: 0 auto;

    h2 {
      font-size: 42px;
      line-height: 42px;
    }

    p {
      font-size: 18px !important;
      line-height: 24px !important;
      margin-top: 20px !important;
    }

    img {
      width: 70%;
    }
  }
}

.accords {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  div {
    width: 33%;
    padding: 20px;
  }
}

.page2_wrapper {
  width: 1300px;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;

      h2 {
      color: white;
      font-size: 62px;
        font-family: $font-extra;
      font-weight: bold;
      font-style: italic;
      margin:0 !important;

        @media screen and (max-width: 768px) {
    font-size: 52px;
  }
    }

  p {
    color: black;
    font-family: "acumin-pro", sans-serif;
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    font-weight: 400;
    margin-top: 20px;
    width: 100%;
    margin: 0 auto;

    margin-bottom: 50px;
  }

  @media screen and (max-width: 1500px) {
    width: 95%;
  }

  @media screen and (max-width: 1000px) {
    width: 92%;

    p {
      font-size: 18px;
      line-height: 24px;
      margin-top: 0;
      width:92%;
    }

    img {

      width: 10%;
    }
  }
}

.page1_wrapper {
  width: fit-content;
  display: flex;
  margin: 0 auto;
  padding-top: 60px;

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    width: 92%;
    margin-top: -20px;
  }

  @media screen and (max-width: 768px) {
    margin-top: -70px;
  }

  .page1_left {
    width: 550px;
    padding-left: 50px;

    @media screen and (max-width: 1000px) {
      display: none;
    }

    img {
      width: 100%;
      margin-top: -50px;
      margin-bottom: -120px;
    }

    @media screen and (max-width: 1500px) {
      width: 350px;
      padding-right: 100px;
      &.p1l_bec-sucre {
        padding-right: 130px;
        margin-left: -100px;
      }
      &.p1l_gastronomaniac {
        padding-right: 130px;
        margin-left: -140px;
      }
      &.p1l_street-lover {
        padding-right: 130px;
        margin-left: -140px;
        margin-bottom: 50px;
      }
      &.p1l_aperitivore {
        padding-right: 140px;
        margin-left: -100px;
      }
      &.p1l_traditionista {
        padding-right: 150px;
        margin-bottom: 70px;
        margin-left: -60px;
      }
      &.p1l_viandard {
        margin-left: -120px;
      }
      img {
        width: 150%;
      }
    }

    @media screen and (max-width: 1100px) {
      padding-right: 50px;
      width: 300px;
      &.p1l_bec-sucre {
        padding-right: 90px;
      }
      &.p1l_gastronomaniac {
        padding-right: 90px;
        margin-left: -110px;
      }
      &.p1l_street-lover {
        padding-right: 90px;
        margin-left: -140px;          
        margin-bottom: 50px;
      }
      &.p1l_aperitivore {
        padding-right: 90px;
        margin-left: -140px;
      }
      &.p1l_traditionista {
        padding-right: 82px;
        margin-left: -80px;
      }
      &.p1l_viandard {
        margin-left: -60px;
      }
      img {
        width: 140%;
        margin-top: 80px;
      }
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }

  .page1_right {
    width: 870px;
    text-align: center;
    padding-top: 80px;

    h3 {
      color: white;
      font-size: 62px;
      line-height: 62px;
      font-family: $font-extra;
      font-weight: bold;
      font-style: italic;
      margin-bottom: -10px;
      padding-right: 10px;
    }

    h2 {
      color: white;
      font-size: 62px;
      line-height: 62px;
      font-family: $font-extra;
      font-weight: bold;
      font-style: italic;
      padding-right: 16px;
    }

    p {
      color: black;
      font-family: "acumin-pro", sans-serif;
      font-weight: 500;
      font-size: 19px;
      line-height: 25px;
      font-weight: 400;
      margin-top: 20px;
      width: 100%;
      margin: 0 auto;
    }

    img {
      margin-top: 10px;
      margin-bottom: 0px;
      max-width: max-content;
    }

    @media screen and (max-width: 1500px) {
      width: 550px;
    padding-top: 20px;      

      p {
        margin-top: 20px;
        width: 100%;
      }

      img {
        width: 100%;
        margin-bottom: -20px;
      }
    }

    @media screen and (max-width: 1000px) {
      width: 100%;

      h2,
      h3 {
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 10px;
        margin-top: 20px;
      }

      p {
        width: 92%;
        font-size: 18px;
        line-height: 24px;
      }
    }

     @media screen and (max-width: 768px) {
       h2 {
         font-size:29px;
       }

h3 {
  margin-bottom:-10px;
}
        img {
         
        margin-bottom: -40px;
      }

     }   
  }
}

.resultats_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.bec-sucre {
  background-image: url("/img/profils/fond-bec-sucre.jpg");
  @media screen and (max-width: 1290px) {
    background-image: url('/img/profils/fond-bec-sucre_v.jpg');
  }

}

.aperitivore {
  background-image: url("/img/profils/fond-aperitivore.jpg");
  @media screen and (max-width: 1290px) {
    background-image: url('/img/profils/fond-aperitivore_v.jpg');
  }
}

.gastronomaniac {
  background-image: url("/img/profils/fond-gastronomaniac.jpg");
    @media screen and (max-width: 1290px) {
    background-image: url('/img/profils/fond-gastronomaniac_v.jpg');
  }

}

.street-lover {
  background-image: url("/img/profils/fond-street-lover.jpg");
    @media screen and (max-width: 1290px) {
    background-image: url('/img/profils/fond-street-lover_v.jpg');
  }

}

.traditionista {
  background-image: url("/img/profils/fond-traditionista.jpg");
    @media screen and (max-width: 1290px) {
    background-image: url('/img/profils/fond-traditionista_v.jpg');
  }

}

.veggie-gourmand {
  background-image: url("/img/profils/fond-veggie-gourmand.jpg");
    @media screen and (max-width: 1290px) {
    background-image: url('/img/profils/fond-veggie-gourmand_v.jpg');
  }

}

.bbq-master {
  background-image: url("/img/profils/fond-bbq-master.jpg");
    @media screen and (max-width: 1290px) {
    background-image: url('/img/profils/fond-BbpMaster_v.jpg');
  }

}

.viandard {
  background-image: url("/img/profils/fond-bbq-master.jpg");
    @media screen and (max-width: 1290px) {
    background-image: url('/img/profils/fond-BbpMaster_v.jpg');
  }

}

.menu_resultats1 {
  position: absolute;
  top: 100px;
}

.reload {
  width: 41px;
  margin-left: 70px;
  cursor: pointer;

  @media screen and (max-width: 1000px) {
    margin-left: 20px;
  }
}

.facebook {
  width: 40px;
  margin-right: 70px;
  cursor: pointer;

  @media screen and (max-width: 1000px) {
    margin-right: 20px;
    width: 35px;
  }
}

.menu_resultats1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.VueCarousel-slide {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 12px;
  text-align: center;
}

.accord {
  padding: 5%;

  img {
    width: 100%;
    border-radius: 50px;
  }

  h2 {
    color: black;
    font-size: 30px;
    margin-top: 20px;
  }

  p {
      margin-top: 10px;
    font-size: 15px;
    line-height: 19px;
  }

    .accord-text {
        padding-left: 7px;
        padding-right: 7px;
        padding-bottom: 1px;

        b {
           font-family: Arial, Helvetica, sans-serif !important;
           font-weight:bold;
        }
    }
  .VueCarousel-pagination {
    ul {
      display: none !important;
    }
  }

  .VueCarousel-navigation-next::after {
    content: url("/accords/left.png");
  }

  .VueCarousel-navigation-prev::after {
    content: url("/accords/right.png");
  }
}

.carousel-resultats {
  width: 98%;
  margin: 0 auto;
  margin-top:-20px;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
}

.accord {

  width:90%;
  img {
     width:100% !important;
    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }
}

.next-accords {
      @media screen and (max-width: 768px) {
      
    }
}

.VueCarousel-slide {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.ascendant {
  text-transform: lowercase;
}
@media screen and (max-width: 1500px) {
  .glass-veggie {
    width: 130% !important;
  }
}
@media screen and (max-width: 1100px){
  .glass-veggie {
    width: 125%;
    margin-top: 60px;
  }
}

.fix-up {
  margin-bottom:-40px;
  @media screen and (max-width: 1000px) {
      margin-bottom:0px;
  }
}

@media screen and (max-width: 1000px) {
.page2_wrapper img {
     width: unset;
}}

</style>